<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewInvoiceItemTemplateForm"
        ref="addNewInvoiceItemTemplateForm"
        v-model="isFormValid"
        class="custom-form pt-8"
        @submit.prevent="submitNewInvoiceItemTemplateForm"
      >
        <InvoiceTemplateForm
          :invoice-item-template="invoiceItemTemplate"
          @update="updateFormValue"
        />
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewInvoiceItemTemplateForm"
      >
        Dodaj usługę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import InvoiceTemplateForm from './Partials/InvoiceItemTemplateForm'
import updateFormValue from '../../mixins/updateFormValue.vue'
import rules from '../../utils/validators'
import { parseAsBasicUnit } from '../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    InvoiceTemplateForm,
  },
  mixins: [updateFormValue],
  data () {
    return {
      rules,
      isFormValid: true,
      invoiceItemTemplate: {
        name: null,
        netValue: null,
        vatPercentage: null,
      }
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      isProcessing: state => state.invoiceItemTemplates.isProcessing
    })
  },
  methods: {
    ...mapActions({
      addNewInvoiceItemTemplate: 'invoiceItemTemplates/addNewItem',
      getInvoiceItemTemplates: 'invoiceItemTemplates/getItems',
      closeDialog: 'layout/closeDialog'
    }),
    submitNewInvoiceItemTemplateForm () {
      this.$refs.addNewInvoiceItemTemplateForm.validate()
      if (this.isFormValid) {
        const netValue = parseAsBasicUnit(this.invoiceItemTemplate.netValue, 100)
        const params = {
          ...this.invoiceItemTemplate,
          netValue,
          departmentId: this.department.id
        }
        this.addNewInvoiceItemTemplate({ params })
          .then(() => {
            this.getInvoiceItemTemplates()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
